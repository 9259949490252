<template>
  <transition name="fade" appear>
    <div class="home">
      <el-image style="width: 942px; height: 238px" :src="url" fit="cover"></el-image>
      <div class="tips"> 温馨提示：请使用IE10或360极速浏览器体验此模块</div>
      <div class="content">
        <div class="title">
          <div class="title_left">
            <div class="title_top">{{ title }}</div>
            <div class="title_bottom">{{ title2 }}</div>
            
          </div>
          <div class="title_right"></div>
        </div>

        <template v-for="(item, index) in List">
          <div :key="index">
            <div :class="[index == List.length - 1 ? 'list-item-bottom' : 'list-item']" @click="geDetail(index)">
              <el-row>
                <el-col :span="20">
                  <div class="item-content">{{ item.title }}</div>
                </el-col>
                <el-col :span="4">
                  <div class="item-time">{{ item.createTime |dataFormat }}</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      url: "http://my.kidsbrown.cn/resources/images/family.png",
      title: "HOME LEARNING",
      title2: "家庭学习",
      List: [],
    };
  },
  methods: {
    //获取家庭学习列表
    getFamilyList(){
      var getDate = {};
      var condition = {};
      let a = sessionStorage.getItem("userInfo")
      if(a==null){
        condition.promptCode = "rz"
      }else{
        let promptCode = JSON.parse(sessionStorage.getItem("userInfo")).promptCode; 
        if(promptCode == "kj" || promptCode == "KJ"){
          condition.promptCode = "kj"
        }else if(promptCode == "rz" || promptCode == "RZ"){
          condition.promptCode = "rz"
        }else{
          return
        }
      }
      getDate.condition = condition;
      this.$api.home.getFamilyList(getDate).then((res) => {
        if (res.data.code === 200) {
          this.List = res.data.data.data;
        }
      });
    },
    //前往详情
    geDetail(index) {
      this.$router.push({
        path: "/familyDetail",
        query:{
            code:this.List[index].code
        }
      });
    },
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "4");
    this.getFamilyList();
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  margin-top: 20px;
  background-color: white;
  .tips{
   width: 500px;
   height: 20px;
  color: #666;
   margin-left: 50.5%;
   font-size: 9px;
  }
  .content {
    margin: 60px 100px 0;
    .title {
      display: flex;
      .title_left {
        display: flex;
        flex-direction: column;
        .title_top {
          font-size: 20px;
          color: #f8b229;
          font-weight: bold;
        }
        .title_bottom {
          font-size: 20px;
          color: #f8b229;
          text-align: left;
        }
      }
      .title_right {
        width: 255px;
        border-bottom: 2px solid #f8b229;
      }
    }
    .list-item {
      padding: 19px 0 8px;
      border-bottom: 1px solid #ededed;
    }
    .list-item:hover{
      cursor: pointer;
    }
    .item-content {
      text-align: left;
      font-size: 18px;
      color: #666;
    }
    .item-time {
      font-size: 16px;
      text-align: right;
      color: #666;
    }
    .list-item-bottom {
      padding: 19px 0 20px;
      border: none;
    }
    .list-item-bottom:hover{
     cursor: pointer;
    }
  }
}
</style>
